export enum UserPermission {
  AgreementList = 'agreementList',
  RulesetBuilder = 'rulesetBuilder',
  Admin = 'admin',
  AccessorialServices = 'accessorialServices',
  AccessorialGroups = 'accessorialGroups',
  FscTemplate = 'fscTemplate',
  RulesetView = 'rulesetView',
  Audit = 'audit',
  AuditMaintenance = 'auditMaintenance',
  TagForAudit = 'tagForAudit',
  OffShore = 'offShore',
  FuelPrice = 'fuelPrice',
  ZonePoints = 'zone-points',
  Agreements = 'agreements',
  RulesetDetail = 'rulesetDetail',
  MaintenanceSales = 'maintenanceSales'
}
