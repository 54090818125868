import { Injectable } from '@angular/core';
import { ConfigManagerProperties } from '../../shared/enums/config-manager-properties.enum';
import { ConfigManagerService } from '@xpo-ltl/config-manager';
import { CsvExportParams } from 'ag-grid-community';
import { ConstantsService } from '../../shared/services/constants/constants.service';
import { ErrorPipe } from '../../shared/pipes/error/error.pipe';
import { XpoFacetFilterControlChangeEvent, XpoSnackBar } from '@xpo-ltl/ngx-ltl-core';
import { XpoGrid } from '@xpo-ltl/ngx-grid';

@Injectable({
  providedIn: 'root',
})
export class ApplicationUtilsService {
  constructor(public configManagerService: ConfigManagerService, public constantsService: ConstantsService) { }

  buildVersion: string = this.configManagerService.getSetting<string>(ConfigManagerProperties.buildVersion);
  readonly helpLink =
    'https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Accessorial%20Management.aspx';
  readonly releaseNotesLink =
    'https://xpologistics.sharepoint.com/sites/150/ttdd/LTLservicecenter/SitePages/Edge%20Accessorial%20Management%20Release%20Notes.aspx';

  storageApplicableFilterOptions: string =
    'xpo-facet-filter-section[sectionName="Applicable"] div.xpo-FacetFilterEnum-container mat-checkbox';
  storageBilledFilterOptions: string =
    'xpo-facet-filter-section[sectionName="Billed"] xpo-option-facet-filter mat-radio-group > mat-radio-button';

  getStorageApplicableFilterOption(index: number): HTMLElement {
    return document
      .querySelectorAll(this.storageApplicableFilterOptions)[index]?.querySelector('label');
  }

  getStorageBilledFilterOption(index: number): HTMLElement {
    return document
      .querySelectorAll(this.storageBilledFilterOptions)[index]?.querySelector('label');
  }

  clickApplicableFilterOption(index: number) {
    this.getStorageApplicableFilterOption(index)?.click();
  }

  clickBilledFilterOption(index: number) {
    this.getStorageBilledFilterOption(index)?.click();
  }

  isFacetFiltered(event: XpoFacetFilterControlChangeEvent): boolean {
    let isFacetFiltered: boolean = false;
    let eventValue = event?.value;
    if (eventValue?.length) {
      eventValue?.forEach(element => {
        if (element?.selected === true) isFacetFiltered = true; return;
      });
    } else {
      return eventValue?.selected;
    }
    return isFacetFiltered;
  }

  isNotEmptyValue(value: string): boolean {
    return value !== this.constantsService.EMPTY_VALUE && !!value;
  }

  getCsvExportParameters(type: string, filtered: boolean): CsvExportParams {
    const constants: ConstantsService = this.constantsService;
    let fileName: string;
    switch (type) {
      case constants.STORAGE: {
        fileName = filtered ? constants.STORAGE_FILTERED_CSV : constants.STORAGE_CSV;
        break;
      }
      case constants.DETENTION: {
        fileName = filtered ? constants.DETENTION_FILTERED_CSV : constants.DETENTION_CSV;
        break;
      }
    }
    return {
      fileName: fileName
    };
  }

  openGridErrorMessage(snackBar: XpoSnackBar, title: string, error: any, time?: number): void {
    const message = new ErrorPipe().transform(error);
    snackBar.open({
      message: title,
      detailedMessage: message.search('modified') === -1 ? message : message + 'Recharge grid',
      status: 'error',
      matConfig: {
        duration: time ? time : 5000,
        verticalPosition: 'top',
      },
    });
  }

  isColumnHideFilter(gridRef: XpoGrid): boolean {
    let filtered = false;

    for (let column of gridRef?.gridOptions.columnApi.getAllColumns()) {
      if (!column.isVisible()) {
        filtered = true;
        break;
      }
    }
    return filtered;
  }

  addHintToSearch(selector: string, textContent: string): void {
    const node = document.createElement('div');
    node.textContent = textContent;
    node.className = 'mat-hint pro-number-hint';
    document.querySelectorAll(selector)[0]?.appendChild(node);
  }
}
